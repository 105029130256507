import React, { FC } from "react"
import { Helmet } from "react-helmet"

import Layout from "../containers/layout"

// TODO: Complete 404 page
const NotFoundPage: FC = () => (
  <Layout>
    <Helmet>
      <title>404</title>
    </Helmet>
  </Layout>
)

export default NotFoundPage
